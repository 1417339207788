import React from "react"

const Description = ( line ) => {
  let data = line.data.part;
  
  return(
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Part Information <span className={"float-right"}>{line.data.price = "0.00" ? "POA" : line.data.price }</span></h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Part details and applications.</p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Product group</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.field_part_group}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Fitting Position</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <p>Vehicle: { data.field_vehicle_position ? data.field_vehicle_position : "N/A (vehicle)" }<br/> 
              Lateral: { data.field_lateral_fitemnt ? data.field_lateral_fitemnt : "N/A (Lateral)" } <br/>
              Side: { data.field_side_fitment ? data.field_side_fitment : "N/A (Side)" } <br/>
              Vertical: { data.field_verical_fitment ? data.field_verical_fitment : "N/A (Vertical)" }</p>
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">OE references</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {data.field_oe_referneces_1[0] ? "":"No oe-refs yet"}
              { data.field_oe_referneces_1.map(ref => (
                  <span key={ref.field_oe_ref}>{ref.field_oe_ref}, </span>
              ))}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Notes</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <h2 className="font-semibold">Application notes</h2>
                <p>{data.field_app_note ? data.field_app_note : "No applicaiton notes"}</p>
                <h2 className="font-semibold">SKU notes</h2>
                <p>{data.field_sku_notes? data.field_sku_notes : "No SKU notes"}</p>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  )
}

export default Description;
